import React from 'react'
import { Button } from 'antd';
import { DeleteOutlined, } from '@ant-design/icons';
import { HeaderTitle } from '../../constants';
import { Box, Grid } from '@material-ui/core';
import ContactStyle from '../../Style';
import _ from 'lodash';

export default function RankingOption(props: any) {

    const classes = ContactStyle();

    const onChoiceChange = (index: number, e: any, property: string) => {
        console.log(index, e);
        const tempOptions = _.cloneDeep(props.question.ranks.choices);
        let opt = tempOptions[index];
        opt = { ...opt, [property]: e };
        tempOptions[index] = opt;
        console.log({ tempOptions });
        props.onChange({ ...props.question, ranks: { ...props.question.ranks, choices: tempOptions } });
    }

    const onItemChange = (index: number, e: any, property: string) => {
        console.log(index, e);
        const tempOptions = _.cloneDeep(props.question.ranks.items);
        let opt = tempOptions[index];
        opt = { ...opt, [property]: e };
        tempOptions[index] = opt;
        console.log({ tempOptions });
        props.onChange({ ...props.question, ranks: { ...props.question.ranks, items: tempOptions } });
    }

    const addChoice = () => {
        const opt = _.cloneDeep(props.question.ranks.choices);
        opt.push({ label: '', value: '' });
        props.onChange({ ...props.question, ranks: { ...props.question.ranks, choices: opt } });
    }

    const removeChoice = (index: any) => {
        const opt = _.cloneDeep(props.question.ranks.choices);
        opt.splice(index, 1);
        props.onChange({ ...props.question, ranks: { ...props.question.ranks, choices: opt } });
    }

    const removeItem = (index: any) => {
        const opt = _.cloneDeep(props.question.ranks.items);
        opt.splice(index, 1);
        props.onChange({ ...props.question, ranks: { ...props.question.ranks, items: opt } });
    }

    const addItem = () => {
        const opt = _.cloneDeep(props.question.ranks.items);
        opt.push({ label: '', value: '' });
        props.onChange({ ...props.question, ranks: { ...props.question.ranks, items: opt } });
    }

    return (
        <Grid container spacing={2} style={{ marginTop: 15, width: '100%' }}>
            <Grid item xs={6}>
                <Grid container spacing={1}>
                    {props.question.ranks.choices && props.question.ranks.choices.map((data: any, i: number) => {
                        return (
                            <Grid item xs={12} style={{ border: '1px solid lightgray', position: 'relative' }} key={i}>
                                <Box>
                                    <HeaderTitle dataIndex={i} title={data.label} onHeaderChange={(i: any, label: any) => onChoiceChange(i, label, 'label')} />
                                    <HeaderTitle dataIndex={i} title={data?.value} onHeaderChange={(i: any, value: any) => onChoiceChange(i, value, 'value')} />
                                </Box>
                                <Box className={classes.deleteIconPosition} id="popover-anchor1" onClick={() => removeChoice(i)}>
                                    <DeleteOutlined className={classes.deleteIcon} />
                                </Box>
                            </Grid>
                        )
                    })}
                    <Grid item xs={12}> <Button onClick={addChoice}> Add Choice </Button></Grid>
                </Grid>
            </Grid>

            <Grid item xs={6}>
                <Grid container spacing={1}>
                    {props.question.ranks.items && props.question.ranks.items.map((data: any, i: number) => {
                        return (
                            <Grid item xs={12} style={{ border: '1px solid lightgray', position: 'relative' }} key={i}>
                                <Box>
                                    <HeaderTitle dataIndex={i} title={data.label} onHeaderChange={(i: any, label: any) => onItemChange(i, label, 'label')} />
                                    <HeaderTitle dataIndex={i} title={data?.value} onHeaderChange={(i: any, value: any) => onItemChange(i, value, 'value')} />
                                </Box>
                                <Box className={classes.deleteIconPosition} id="popover-anchor1" onClick={() => removeItem(i)}>
                                    <DeleteOutlined className={classes.deleteIcon} />
                                </Box>
                            </Grid>
                        )
                    })}
                    <Grid item xs={12}> <Button onClick={addItem}> Add Item </Button></Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}