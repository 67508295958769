import { createSlice } from '@reduxjs/toolkit';
import LanguageService from './LanguageService';

export const languageSlice = createSlice({
  name: 'languageSlice',
  initialState: {
    languageList: [],
    selectedLanguageList: [],
    defaultLanguage: {},
  },
  reducers: {
    ...LanguageService,
  },
});

export const { addLanguage, addSelectedLanguage, addDefaultLanguage } = languageSlice.actions;

export default languageSlice.reducer;
