import { CloseOutlined, ConsoleSqlOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Box, IconButton, MenuItem } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import { Col, Form, Radio, Row, Select, Button, Input, Typography } from 'antd';
import React, { useEffect, useLayoutEffect } from 'react';
import ContactStyle from './Style';
import { conditions } from './utils';
import { fetchItems } from '../../../Service/Service';
import _ from 'lodash';
import Grow from '@mui/material/Grow';
import { MULTIPLE_SELECT, SELECT_ONE } from '../../QuesTypes/Constants';
import TextField from '@mui/material/TextField';
const { Option } = Select;

export default function ConditionSkipLogic({
  question,
  questions,
  logicList,
  updateLogic,
  addLogic,
  removeLogic,
  setSkipLogicRender,
  onEvent,
}: any) {
  const classes = ContactStyle();

  const [isSelect, setIsSelect] = React.useState(false);
  const [isConditionSelect, setIsCondtitionSelect] = React.useState(false);
  const [options, setOptions] = React.useState<any[]>([]);
  console.log('options: ', question);
  useLayoutEffect(() => {
    if (logicList.length === 0) {
      addLogic();
    }
  }, []);
  console.log('in skipLogic questions: ', questions, logicList);

  const setCondition = (logic: any, index: any, value: any) => {
    console.log('condition event: ', value);
    const ob = JSON.parse(JSON.stringify(logic));
    if (value) {
      ob.condition = { value: value, label: ob.label };
    } else {
      if (ob.condition) {
        delete ob.condition;
        if (ob.value) delete ob.value;
      }
    }
    updateLogic(index, ob);
  };

  const setQuestion = (logic: any, index: any, value: any) => {
    const ob = JSON.parse(JSON.stringify(logic));
    ob.questionName = value;
    ob.question = questions.find((data: any) => data.name === value);
    if (ob.condition) delete ob.condition;
    if (ob.value) delete ob.value;
    updateLogic(index, ob);
  };

  const setValue = (logic: any, index: any, data: any) => {
    const ob = _.cloneDeep(logic);
    console.log('value', data);
    ob.value = data;
    updateLogic(index, ob);
  };
  const setCriteria = (data: any) => {
    onEvent('criteria', data);
    // updateLogic(index, ob);
  };

  const setComparisonType = (logic: any, index: any, value: any) => {
    const ob = _.cloneDeep(logic);
    ob.comparisonType = value;
    updateLogic(index, ob);
  };

  const getOptions = async (choice: any) => {
    const temp_options: any[] = [];

    if (choice.isDefault) {
      temp_options.push(choice.options);
    } else {
      const opt: any = await fetchItems(choice.id);

      //  console.log('opt', opt);

      opt.map((item: any) => {
        const cat_names = item.itemName;
        if (typeof cat_names !== 'string') {
          const cat_name = cat_names?.filter((cat: any) => cat.language.isDefault);
          if (cat_name[0]?.title !== '') item.option = cat_name[0]?.title;
        }
        item.value = item.itemValue;
      });
      temp_options.push(opt);
    }
    //  console.log('optionss\n\n\n\n\n', optionss);
    setOptions(temp_options[0]);

    return options;
  };

  const GetQuestionOptions = ({ logic, index }: any) => {
    if (logic.question && (logic.question.type === SELECT_ONE || logic.question.type === MULTIPLE_SELECT)) {
      return (
        <Select allowClear>
          {logic.question.choice.options.map((data: any, i: number) => (
            <Option value={data.value} key={i}>
              {/* {console.log('data', data.option)} */}
              {data.option}
            </Option>
          ))}
        </Select>
      );
    } else return <Input value={logic.value} onChange={(e) => setValue(logic, index, e.target.value)} />;
  };

  return (
    <>
      <Box className={classes.closeOutlinedIcon}>
        <IconButton
          onClick={() => {
            setSkipLogicRender('');
            onEvent('logicList', []);
          }}
        >
          <CloseOutlined className={classes.icon} />
        </IconButton>
      </Box>

      <Box className={classes.conditionSkipLogicText}>
        <p>Use the (+) and (-) buttons below to add and remove conditions</p>
        <p>
          This question will only be displayed{' '}
          <span>
            {/* <Select defaultValue={'all'} style={{ width: 64 }} onChange={(v: any) => { console.log(v) }}>
                        <Select.Option value="all">All</Select.Option>
                        <Select.Option value="lucy">Lucy</Select.Option>
                        <Select.Option value="Yiminghe">yiminghe</Select.Option>
                    </Select> */}
          </span>{' '}
          if the following conditions are true
        </p>
      </Box>
      {logicList.map((data: any, index: number) => (
        <React.Fragment key={index}>
          <Box className={classes.conditionSkipLogicForm}>
            <IconButton className={classes.minusOutlinedIcon} onClick={(e: any) => removeLogic(index)}>
              <MinusOutlined className={classes.icon} />
            </IconButton>

            <Box margin={3}>
              <Grid item container rowSpacing={1} alignItems="center" xs={11}>
                <Grid item xs={12} md={12} lg={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={4}>
                      <Typography> Select Question: </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {' '}
                      <Select
                        value={data.questionName}
                        onChange={(e: any) => {
                          e ? setIsSelect(true) : setIsSelect(false);
                          setQuestion(data, index, e);

                          //  console.log('questionName', data);
                        }}
                        style={{ width: '100%' }}
                        allowClear
                      >
                        {questions.map((ques: any, index: number) => (
                          <Option value={ques.name} key={index}>
                            {ques.name}
                          </Option>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>

                {data.question && (
                  <Grow in={data.question ? true : false} style={{ transitionDelay: data.question ? '50ms' : '0ms' }}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          <Typography> Condition : </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Select
                            style={{ width: '100%' }}
                            onChange={(e: any) => {
                              setCondition(data, index, e);
                              console.log('questionName', data, e);

                              e !== 'was_not_answered' && e !== 'was_answered'
                                ? setIsCondtitionSelect(true)
                                : setIsCondtitionSelect(false);
                              // console.log('data\n\n\n\n\n', data);
                              if (
                                data.question.type &&
                                (data.question.type === SELECT_ONE || data.question.type === MULTIPLE_SELECT)
                              ) {
                                getOptions(data.question.choice);
                              }
                            }}
                            value={data.condition?.value}
                            allowClear
                          >
                            {conditions.map((c: any, index: any) => (
                              <Select.Option value={c.value} key={index}>
                                {c.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Grid>
                      </Grid>
                      {/* <Form.Item labelAlign="left" labelCol={labelCol} label="Condition" rules={[{ required: true }]}>
                        <Select
                          onChange={(e: any) => {
                            setCondition(data, index, e);
                       //     console.log('questionName', data);

                            e !== 'was_not_answered' && e !== 'was_answered'
                              ? setIsCondtitionSelect(true)
                              : setIsCondtitionSelect(false);
                            console.log('data\n\n\n\n\n', data);
                            if (
                              data.question.type &&
                              (data.question.type === SELECT_ONE || data.question.type === MULTIPLE_SELECT)
                            ) {
                              getOptions(data.question.choice);
                            }
                          }}
                          value={data.condition?.value}
                          allowClear
                        >
                          {conditions.map((c: any, index: any) => (
                            <Select.Option value={c.value} key={index}>
                              {c.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item> */}
                    </Grid>
                  </Grow>
                )}

                {data.question &&
                  data.condition &&
                  data.condition.value !== 'was_not_answered' &&
                  data.condition.value !== 'was_answered' && (
                    <Grow in={true} style={{ transitionDelay: '50ms' }}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            <Typography> Value : </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            {(data.question?.type === SELECT_ONE || data.question?.type === MULTIPLE_SELECT) && (
                              <Select
                                allowClear
                                style={{ width: '100%' }}
                                value={data.value?.value}
                                onChange={(e) => {
                                  const tempData = options.filter((opt: any) => opt.value === e)[0];
                                  setValue(data, index, tempData);
                                }}
                                onClick={() => {
                                  getOptions(data.question.choice);
                                }}
                              >
                                {options.map((logic: any, i: number) => (
                                  <Option value={logic.value} key={i}>
                                    {logic.value}
                                  </Option>
                                ))}
                              </Select>
                            )}

                            {data.question.type !== SELECT_ONE && data.question.type !== MULTIPLE_SELECT && (
                              <Input value={data.value} onChange={(e) => setValue(data, index, e.target.value)} />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grow>
                  )}
              </Grid>
            </Box>
          </Box>
          <br />
        </React.Fragment>
      ))}

      <Grid container>
        <Grid container item justifyContent="right">
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            size="large"
            onClick={(e: any) => {
              if (logicList.length > 0) {
                console.log('im here');
              }
              addLogic();
            }}
          >
            Add Logic
          </Button>
        </Grid>

        {question.criteria && (
          <Grid container item justifyContent="center" xs={12}>
            <TextField
              style={{ width: '100%' }}
              sx={{ mx: 20 }}
              select
              id="standard-basic"
              label="Select a criteria"
              variant="standard"
              value={question.criteria}
              onChange={(e: any) => {
                setCriteria(e.target.value);
              }}
            >
              <MenuItem value="all">Question should match all of these criteria</MenuItem>
              <MenuItem value="any">Question should match any of these criteria</MenuItem>
            </TextField>
          </Grid>
        )}
      </Grid>
    </>
  );
}
export const labelCol = { span: 8, offset: 2 };
