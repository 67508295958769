import _ from 'lodash';

/**
 *  Language Reducers
 */

const addLanguage = (state: any, action: any) => {
  state.languageList = action.payload;
};

const addSelectedLanguage = (state: any, action: any) => {
  state.selectedLanguageList = action.payload;
};

const addDefaultLanguage = (state: any, action: any) => {
  state.defaultLanguage = action.payload;
};

export default { addLanguage, addSelectedLanguage, addDefaultLanguage };
