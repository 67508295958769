import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { requestRefreshToken } from '../features/User/UserService';
import FormDesigner from '../features/FormDesigner/index';
import { useDispatch } from 'react-redux';
import { setQuestionBankProperties } from '../redux/QuesBankSlice';

import { formDefinitionURL, masterDataURL } from '../configs/ServerUrl';
import axiosServices from '../helpers/axiosService';
import queryString from 'query-string';
import { setLayoutSettings } from '../redux/FormSlice';
import { addLanguage, addSelectedLanguage, addDefaultLanguage } from '../redux/LanguageSlice';
import { setQuestions } from '../redux/QuestionSlice';
import { _deserailize } from '../features/FormDesigner/Settings/Options/utils';
import { fetchLanguageURL } from '../configs/ServerUrl';

function App() {
  const refreshTokenhandler = async () => await requestRefreshToken();
  const dispatch = useDispatch();

  const fetchLanguage = async () => {
    const payload = {
      data: {
        type: 'languages',
        attributes: {
          language: {},
        },
      },
    };
    try {
      const response = await axiosServices.post(`${fetchLanguageURL}`, payload);
      const languages = await _deserailize(response.data);

      const defaultLang = languages.filter((l: any) => l.isDefault)[0];
      console.log('defaultLnage', defaultLang);
      dispatch(addLanguage(languages));

      dispatch(addDefaultLanguage(defaultLang));
    } catch (ex) {
      console.log(ex);
    }
  };

  React.useEffect(() => {
    fetchLanguage();
  }, []);

  React.useEffect(() => {
    dispatch(
      setQuestionBankProperties({
        key: 'tags',
        value: [{ label: 'one', value: 12 }],
      }),
    );
    setTimeout(function () {
      refreshTokenhandler();
      FormDesigner;
    }, 100);
  }, []);

  React.useEffect(() => {
    const intervalManager = setInterval(refreshTokenhandler, 290000);
    (async () => {
      const urlParams = queryString.parse(window.location.search);
      try {
        const masterDataResponse = await axiosServices.get(`${masterDataURL}`);
        dispatch(setLayoutSettings({ masterData: masterDataResponse.data }));
      } catch (ex) {}

      try {
        const surveyResponse: any = await axiosServices.post(`${formDefinitionURL}`, { surveyId: urlParams.id });
        dispatch(setLayoutSettings(surveyResponse.data.form));
        dispatch(setQuestions({ questions: surveyResponse.data.questions }));
      } catch (ex) {}
    })();

    return () => {
      clearInterval(intervalManager);
    };
  }, []);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact component={FormDesigner} path="/" />
          <Route exact component={FormDesigner} path="/form-designer" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
