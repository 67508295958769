import { createSlice } from '@reduxjs/toolkit';
import QuesBankService from './QuesBankService';

export const quesBankSlice = createSlice({
    name: 'quesBankSlice',
    initialState: {
        questionList: [],
        tags: [],
    },
    reducers: {
        ...QuesBankService,
    },
});

export const {
    setQuestionBankProperties,
    addToQuesBank,
} = quesBankSlice.actions;

export default quesBankSlice.reducer;
