import _ from "lodash";

/**
 *  QuesBank Reducers
 */
const setQuestionBankProperties = (state: any, action: any) => {
    state[action.payload.key] = action.payload.value;
};

const addToQuesBank = (state: any, action: any) => {
    state.questionList = [..._.cloneDeep(state.questionList), action.payload];
};


export default { setQuestionBankProperties, addToQuesBank };
