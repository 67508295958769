import { createSlice } from '@reduxjs/toolkit';
import FormService from './FormService';

export const formSlice = createSlice({
    name: 'form',
    initialState: {
        formName: '',
        formTitle: '',
        masterData: {},
    },
    reducers: {
        ...FormService,
    },
});

export const {
    setForm,
    setLayoutSettings,
    setMasterData,
} = formSlice.actions;

export default formSlice.reducer;
