import { makeStyles, Theme } from '@material-ui/core';

const ContactStyle = makeStyles((theme: Theme) => ({
  groupSection: {
    width: '96%',
    paddingBottom: '2%',
    marginLeft: '2%',
  },
  groupTextColor: {
    color: '#039be5',
  },
  questionContainer: {
    width: '100%',
    padding: 0,
    paddingRight: '3%',
  },
  accordionSummary: {
    backgroundColor: '#eceff1',
  },
  innerSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  groupIcons: {
    fontSize: '1.2rem',
  },
  iconSection: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '7%',
  },
  addBoxIcon: {
    display: 'none',
    color: 'white',
    fontSize: 16,
    padding: 5,
    '&:hover': {
      color: 'white',
      backgroundColor: '#4dd0e1',
      borderRadius: '20%',
    },
  },
  addBoxIconPosition: {
    borderRadius: '20%',
    position: 'absolute',
    left: -27,
    bottom: 0,
    backgroundColor: 'lightgray',
  },
  cardStyle: {
    transition: 'ease',
    '&:hover': {
      '& $addBoxIcon': {
        display: 'block',
      },
    },
  },
}));

export default ContactStyle;
