/*eslint-disable*/
import { Card, CardContent } from '@material-ui/core';
import React, { useEffect } from 'react';
import { getDefaultValueByQuestionType } from './Service';
import Style from '../Questions/Style';
import { useSelector } from 'react-redux';
import {
  ACKNOWLEDGE,
  AREA,
  AUDIO,
  CALCULATE,
  DATE,
  DATE_TIME,
  DECIMAL,
  MULTIPLE_SELECT,
  PHOTO,
  SELECT_ONE,
  TEXT,
  BARCODE,
  NUMBER,
  FILE,
  NOTE,
  POINT,
  QUESTION_MATRIX,
  RANGE,
  RANKING,
  TIME,
  VIDEO,
  RATING,
  LINE,
} from './Constants';
import { useDispatch } from 'react-redux';
import { add } from '../../../redux/QuestionSlice';
import { Col, Row } from 'antd';
import DatetimeSVG from '../../../Icons/date-time.svg';
import TextSvg from '../../../Icons/text.svg';
import SelectOneSvg from '../../../Icons/select-one.svg';
import MultiSelectSvg from '../../../Icons/select-many.svg';
import NumberSvg from '../../../Icons/number.svg';
import NoteSvg from '../../../Icons/note.svg';
import CalculateSvg from '../../../Icons/calculate.svg';
import DateSvg from '../../../Icons/date.svg';
import DecimalSvg from '../../../Icons/decimal.svg';
import FileSvg from '../../../Icons/file.svg';
import PhotoSvg from '../../../Icons/date.svg';
import AudioSvg from '../../../Icons/audio.svg';
import VideoSvg from '../../../Icons/video.svg';
import RatingSvg from '../../../Icons/rating.svg';
import LocationSvg from '../../../Icons/location.svg';
import BarcodeSvg from '../../../Icons/barcode.svg';
import AcknowledgeSvg from '../../../Icons/acknowledge.svg';
import AreaSvg from '../../../Icons/area.svg';
import RankingSvg from '../../../Icons/ranking.svg';
import MatrixSvg from '../../../Icons/question-matrix.svg';
import RangeSvg from '../../../Icons/range.svg';
import LineSVG from '../../../Icons/line.svg';
import TimeSVG from '../../../Icons/time.svg';

export default function QuestionTypes(props: any) {
  const classes: any = Style();
  const dispatch = useDispatch();
  const defaultLanguage = useSelector((state: any) => state.language.defaultLanguage);
  const selectedLanguage = useSelector((state: any) => state.language.selectedLanguageList);

  useEffect(() => {}, [selectedLanguage]);
  const quesButtons = () => {
    return [
      {
        type: TEXT,
        src: TextSvg,
        label: 'Text',
      },
      {
        type: SELECT_ONE,
        src: SelectOneSvg,
        label: 'Select One',
      },
      {
        type: MULTIPLE_SELECT,
        src: MultiSelectSvg,
        label: 'Multiple Select',
      },
      {
        type: NUMBER,
        src: NumberSvg,
        label: 'Number',
      },
      {
        type: NOTE,
        src: NoteSvg,
        label: 'Note',
      },
      {
        type: CALCULATE,
        src: CalculateSvg,
        label: 'Calculate',
      },
      {
        type: DATE_TIME,
        src: DatetimeSVG,
        label: 'Date Time',
      },
      {
        type: DATE,
        src: DateSvg,
        label: 'Date',
      },
      {
        type: DECIMAL,
        src: DecimalSvg,
        label: 'Decimal',
      },
      {
        type: PHOTO,
        src: PhotoSvg,
        label: 'photo',
      },
      {
        type: POINT,
        src: LocationSvg,
        label: 'Point',
      },
      // QUESTION_MATRIX,
      // RANGE,
      {
        type: RATING,
        src: RatingSvg,
        label: 'Rating',
      },
      {
        type: RANKING,
        src: RankingSvg,
        label: 'Ranking',
      },
      // RANKING,
      // TIME,
      {
        type: VIDEO,
        src: VideoSvg,
        label: 'Video',
      },
      {
        type: AUDIO,
        src: AudioSvg,
        label: 'Audio',
      },
      {
        type: AREA,
        src: AreaSvg,
        label: 'Area',
      },

      {
        type: BARCODE,
        src: BarcodeSvg,
        label: 'Barcode',
      },
      {
        type: FILE,
        src: FileSvg,
        label: 'File',
      },
      {
        type: ACKNOWLEDGE,
        src: AcknowledgeSvg,
        label: 'Acknowledge',
      },
      {
        type: QUESTION_MATRIX,
        src: MatrixSvg,
        label: 'Question Matrix',
      },
      {
        type: LINE,
        src: LineSVG,
        label: 'Line',
      },
      ,
      {
        type: TIME,
        src: TimeSVG,
        label: 'Time',
      },
      {
        type: RANGE,
        src: RangeSvg,
        label: 'Range',
      },
    ];
  };

  const questionBtnClick = (type: string) => {
    dispatch(
      add({
        ...getDefaultValueByQuestionType(type, defaultLanguage, selectedLanguage),
        type,
        path: props.currentQustion.path,
      }),
    );
    props.close();
  };
  return (
    <Card variant="outlined">
      <CardContent>
        <Row>
          {quesButtons().map(({ type, src, label }: any, i: number) => {
            return (
              <Col key={i} span={6} style={{ marginTop: 5, marginBottom: 5, cursor: 'pointer' }}>
                <span key={i} className={classes.iconStyle} onClick={() => questionBtnClick(type)}>
                  <img className={classes.iconStyle} width="30" height="25" src={src} />{' '}
                  <span style={{ paddingLeft: 7 }}></span>
                  {label}
                  <p></p>
                </span>
              </Col>
            );
          })}
        </Row>
      </CardContent>
    </Card>
  );
}
