import { Form, Input, Button, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import Style from '../Style';
import _, { isArray, remove } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import { MinusOutlined, DeleteOutlined, PlusOutlined, DownOutlined, CaretDownOutlined } from '@ant-design/icons';
import axiosCategoryServices from '../../../../helpers/axiosCategoryService';
import { fetchCategoryURL, fetchItemURL } from '../../../../configs/ServerUrl';
import { notification } from 'antd';
import { _deserailize } from './utils';
import Select from 'react-select';
import { items } from '../../../SortableContainer/constant';
import { Grid, Tabs, Tab, Box, Collapse, IconButton, Typography } from '@mui/material';
import Grow from '@mui/material/Grow';
import { v4 as uuidv4, v4 } from 'uuid';
import ContactStyle from '../SkipLogic/Style';
import { useSelector } from 'react-redux';

export default function Choice(props: any) {
  const [loadings, setLoadings] = useState<boolean>();
  const [category, setCategory] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [items, setItems] = useState<any[]>([]);
  const fetchItemNumber = useRef<any>(1);
  // const [value, setValue] = React.useState(props.question.choice.isDefault);
  const defaultLanguage = useSelector((state: any) => state.language.defaultLanguage);
  const selectedLanguage = useSelector((state: any) => state.language.selectedLanguageList);
  const [collapse, setCollapse] = useState<any[]>([]);

  console.log('d', selectedCategory);
  console.log('items: ', items);

  const getDefaultCategory = (categories: any) => {
    const temp_categories = categories;
    temp_categories.map((category: any) => {
      const cat_names = category.categoryName;
      if (typeof cat_names !== 'string') {
        const cat_name = cat_names?.filter((cat: any) => cat.language.isDefault);
        if (cat_name[0]?.title !== '') category.label = cat_name[0]?.title;
      }
      category.label = category.categoryValue;
      category.value = category.id;
      if (category.parent && category.parent !== 'string') {
        console.log('parent', category.parent);
        category.parent = category.parent.categoryName[0].title;
      }
    });

    console.log(categories);
    return temp_categories;
  };

  const fetchCategory = async () => {
    const payload = {
      data: {
        type: 'categories',
        attributes: {
          masterdataCategory: {},
          searchString: '',
          updateFlag: false,
        },
      },
    };
    try {
      setLoadings(true);
      const response = await axiosCategoryServices.post(`${fetchCategoryURL}`, payload);
      const categories = await _deserailize(response.data);
      setCategory(getDefaultCategory(categories));
      setLoadings(false);

      notification['success']({
        message: 'Success',
        description: 'Category Fetched !',
      });
    } catch (ex) {
      notification['error']({
        message: 'Error',
        description: 'Cant fetch category',
      });
    }
  };

  const fetchItems = async (value: any) => {
    const payload = {
      data: {
        type: 'Items',
        attributes: {
          masterData: {
            categoryId: value,
          },
          searchString: '',
          updateFlag: false,
          pageSize: fetchItemNumber.current,
        },
      },
    };
    try {
      const response = await axiosCategoryServices.post(`${fetchItemURL}`, payload);
      const items = await _deserailize(response.data);
      setItems([]);

      setItems(items);
      notification['success']({
        message: 'Success',
        description: 'Items Fetched !',
      });
    } catch (ex) {
      notification['error']({
        message: 'Error',
        description: 'Cant fetch items',
      });
    }
  };

  useEffect(() => {
    console.log('ques', props.question.choice);
    if (!props.question.choice.isDefault) {
      console.log('default');
      setSelectedCategory(props.question.choice['default']);
      console.log('id', props.question.choice);
      fetchItems(props.question.choice.id);

      props.onChange({
        ...props.question,
        choice: {
          options: [
            {
              option: 'option 1',
              value: 1,
              uuid: v4(),
            },
            {
              option: 'option 2',
              value: 2,
              uuid: v4(),
            },
          ],
          isDefault: false,
          categoryValue: props.question.choice.categoryValue,
          id: props.question.choice.id,
          default: props.question.choice.default,
        },
      });
    } else {
      props.onChange({
        ...props.question,
        choice: {
          options: props.question.choice.options,
          default: null,
          isDefault: true,
        },
      });
    }

    fetchCategory();
  }, []);

  const classes = Style();
  const optionValueChange = (index: number, e: any, code: any) => {
    const tempOptions = _.cloneDeep(props.question.choice);
    let opt = tempOptions.options[index];
    if (e.target.name === 'value') {
      opt = { ...opt, [e.target.name]: e.target.value };
      tempOptions.options[index] = opt;
      console.log({ tempOptions });
      props.onChange({ ...props.question, choice: tempOptions });
    } else if (e.target.name === 'option') {
      console.log(e.target.name, code, e.target.value, opt[e.target.name], opt);
      opt = { ...opt, [e.target.name]: { ...opt[e.target.name], [code]: e.target.value } };
      tempOptions.options[index] = opt;
      console.log({ tempOptions });
      props.onChange({ ...props.question, choice: tempOptions });
    }
  };

  const addOption = () => {
    const opt = _.cloneDeep(props.question.choice);
    opt.options.push({ option: '', value: '', uuid: v4() });

    props.onChange({ ...props.question, choice: opt });
  };

  const removeOption = (index: any) => {
    const opt = _.cloneDeep(props.question.choice);
    opt.options.splice(index, 1);
    props.onChange({ ...props.question, choice: opt });
  };

  return (
    <Grid container minHeight="300px">
      <Box sx={{ width: '100%' }} id="nboxxx">
        <Tabs
          onChange={(event: React.SyntheticEvent, newValue: any) => {
            console.log('newValue', newValue);
            // if (newValue === 'default') {
            //   props.onChange({g

            //     ...props.question,
            //     options: [
            //       { option: 'option 1', value: 1 },
            //       { option: 'option 2', value: 2 },
            //     ],
            //   });
            // }
            const tempOptions = _.cloneDeep(props.question.choice);

            newValue === 'default' ? (tempOptions.isDefault = true) : (tempOptions.isDefault = false);
            console.log({ tempOptions });
            props.onChange({ ...props.question, choice: tempOptions });
            // setValue(newValue);
          }}
          centered
          value={props.question.choice.isDefault ? 'default' : 'master'}
          variant="fullWidth"
          aria-label="wrapped label tabs example"
        >
          <Tab wrapped value="default" label="Default" />
          <Tab wrapped value="master" label="Master" />
        </Tabs>
      </Box>
      {props.question.choice.isDefault && (
        <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
          {props.question.choice.options &&
            props.question.choice.options.map((data: any, index: any) => (
              <React.Fragment key={index}>
                <Box className={classes.conditionSkipLogicForm} sx={{ width: '100%', marginRight: '20px' }}>
                  <Grid container justifyContent="flex-end">
                    <IconButton
                      className={classes.minusOutlinedIcon}
                      onClick={() => {
                        removeOption(index);
                      }}
                    >
                      <MinusOutlined className={classes.icon} />
                    </IconButton>
                  </Grid>
                  <Grid container justifyContent="center" alignItems="center" sx={{ py: '20px', px: '20px' }}>
                    <Grid container alignItems="center">
                      <Grid item xs={2}>
                        <Typography variant="subtitle2">{`Option ( ${defaultLanguage.code} - default ):`} </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          name="option"
                          value={data.option[defaultLanguage.code]}
                          onChange={(e: any) => optionValueChange(index, e, defaultLanguage.code)}
                        />
                      </Grid>
                      <Grid item xs={2} sx={{ marginLeft: '40px' }}>
                        <Typography variant="subtitle2">Value </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Input
                          name="value"
                          value={data.value}
                          onChange={(e: any) => optionValueChange(index, e, defaultLanguage.code)}
                        />
                      </Grid>
                    </Grid>

                    {/* {selectedLanguage.map((lang: any, i: number) => (
                      <Grid container alignItems="center" key={i} sx={{ my: '20px' }}>
                        <Grid item xs={2}>
                          <Typography variant="subtitle2">{`Option ( ${lang.code} - default ):`} </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Input
                            name="option"
                            value={data.option[lang.code]}
                            onChange={(e: any) => optionValueChange(index, e, lang.code)}
                          />
                        </Grid>
                        <Grid item xs={2} sx={{ marginLeft: '40px' }}>
                          <Typography variant="subtitle2">Value </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Input
                            name="value"
                            value={data.value}
                            onChange={(e: any) => optionValueChange(index, e, lang.code)}
                          />
                        </Grid>
                      </Grid>
                    ))} */}
                  </Grid>
                  {/* <Box margin={3}>
                    <Grid item container rowSpacing={1} alignItems="center" xs={11}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            <Typography> Select Question: </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            {' '}
                            <Select
                              value={data.questionName}
                              onChange={(e: any) => {
                                e ? setIsSelect(true) : setIsSelect(false);
                                setQuestion(data, index, e);

                                //  console.log('questionName', data);
                              }}
                              style={{ width: '100%' }}
                              allowClear
                            >
                              {questions.map((ques: any, index: number) => (
                                <Option value={ques.name} key={index}>
                                  {ques.name}
                                </Option>
                              ))}
                            </Select>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box> */}
                </Box>
                <br />
              </React.Fragment>
            ))}

          <Grid container>
            <Grid container item justifyContent="right" sx={{ marginRight: '20px', marginTop: '20px' }}>
              <Button type="dashed" icon={<PlusOutlined />} size="large" onClick={addOption}>
                Add Choice
              </Button>
            </Grid>
          </Grid>
          {/* {props.question.choice.options &&
            props.question.choice.options.map((data: any, i: any) => (
              <Grid container item xs={12} key={i} justifyContent="center">
                {' '}
                <div className={classes.choiceInline}>
                  <Form.Item label="Option:" rules={[{ required: true }]}>
                    <Input name="option" value={data.option} onChange={(e: any) => optionValueChange(i, e)} />
                  </Form.Item>
                  <Form.Item className={classes.gapBetweenGroupField} label="Value:" rules={[{ required: true }]}>
                    <Input name="value" value={data.value} onChange={(e: any) => optionValueChange(i, e)} />
                  </Form.Item>
                  <DeleteOutlined className={classes.deleteForeverIcon} onClick={() => removeOption(i)} />
                </div>{' '}
              </Grid>
            ))} */}

          {/* <div className={classes.addIconCenterBtn}>
            {props.question.choice && (
              <Button
                style={{ whiteSpace: 'normal', height: 'auto' }}
                type="dashed"
                className={classes.addIconCenter}
                onClick={addOption}
              >
                <PlusOutlined /> Click to add another response
              </Button>
            )}
          </div> */}
        </Grid>
      )}

      {!props.question.choice.isDefault && (
        <Grid container justifyContent="center" alignItems="flex-start">
          <Grid
            container
            item
            justifyContent={props.question.choice.default && items.length > 0 ? '' : 'center'}
            sx={{ my: '20px' }}
          >
            <Grid item xs={4}>
              Please select a category
            </Grid>
            <Grid item xs={6}>
              <Select
                isClearable
                defaultValue={props.question.choice.isDefault ? '' : props.question.choice.default || 'Select'}
                options={category}
                isLoading={loadings}
                onChange={(event: any) => {
                  console.log('eventtt', event);
                  // props.deleteSkipLogic(props.question.id);
                  fetchItemNumber.current = 1;
                  setSelectedCategory(event);
                  fetchItems(event?.value);

                  if (event) {
                    props.onChange({
                      ...props.question,
                      choice: {
                        isDefault: props.question.choice.isDefault,
                        options: props.question.choice.options,
                        default: event,
                        categoryValue: event?.categoryValue,
                        id: event.id,
                      },
                    });
                  } else {
                    props.onChange({
                      ...props.question,
                      choice: {
                        isDefault: props.question.choice.isDefault,
                        options: props.question.choice.options,
                        default: null,
                      },
                    });
                  }
                }}
              />
            </Grid>
          </Grid>

          {props.question.choice.default && (
            <Grid>
              {items && items.length === 0 && (
                <Grid container item>
                  No items available
                </Grid>
              )}

              {items && items.length > 0 && (
                <Grid container item xs={12} justifyContent="center" alignItems="center" spacing={4}>
                  {items.map((data: any, i: any) =>
                    data.itemName.map((name: any, i: number) => {
                      return (
                        <Grid item container key={i} spacing={2}>
                          <Grid container item xs={6}>
                            <Grid item xs={4}>
                              {`Option (${name.language.name}):`}
                            </Grid>
                            <Grid item xs={8}>
                              <Input disabled name="option" value={name.title} />
                            </Grid>
                          </Grid>
                          <Grid container item xs={6}>
                            <Grid item xs={4}>
                              Value
                            </Grid>
                            <Grid item xs={8}>
                              <Input disabled name="value" value={data.itemValue} />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    }),
                  )}
                </Grid>
              )}
            </Grid>
          )}

          {items.length > 0 && props.question.choice.default && (
            <Button
              style={{ whiteSpace: 'normal', height: 'auto' }}
              type="dashed"
              className={classes.addIconCenter}
              onClick={() => {
                fetchItemNumber.current = fetchItemNumber.current + 1;
                fetchItems(selectedCategory?.id);
              }}
            >
              <CaretDownOutlined /> See more
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
}
