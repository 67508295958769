import React from 'react';
import { Col, Form, Input, Checkbox, Row } from 'antd';
import Style, { labelCol } from '../Style';

export default function GroupOptions(props: any) {
    const classes = Style();

    const onQuestionChange = (e: any) => {
        props.onChange({ ...props.question, [e.target.name]: e.target.value });
    }

    return (
        <div className={classes.quesOptionSection}>
            <Row justify='start'>
                <Col span='20'>
                    <Form.Item labelAlign='left' labelCol={labelCol} label="Name:">
                        <Input name="name" value={props.question.name} onChange={onQuestionChange} />
                    </Form.Item>
                </Col>

                <Col span='20' >
                    <Form.Item labelAlign='left' labelCol={labelCol} label="Data Column Name:">
                        <Input
                            name="dataColumnName"
                            value={props.question.dataColumnName}
                            className={classes.inputSize}
                            onChange={onQuestionChange}
                        />
                    </Form.Item>
                </Col>

                <Col span='20'>
                    <Form.Item labelAlign='left' labelCol={labelCol} label="Group Appearance:">
                        <Checkbox
                            name="parameters"
                            checked={props.question.groupAppearance}
                            onChange={() => props.onChange({ ...props.question, groupAppearance: !props.question.groupAppearance })} />
                    </Form.Item>
                </Col>

                <Col span='20'>
                    <Form.Item labelAlign='left' labelCol={labelCol} label="Repeat:">
                        <Checkbox
                            name="repeat"
                            checked={props.question.repeat}
                            onChange={() => props.onChange({ ...props.question, repeat: !props.question.repeat })}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}