import { Form, InputNumber, Button } from 'antd';
import Style from '../Style';
import _ from 'lodash';
import React from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

export default function Range(props: any) {

    const classes = Style();

    const optionValueChange = ( name:string,e: any) => {
         const tempRange = _.cloneDeep(props.question.range);
        // let opt = tempOptions[index];
        // opt = { ...opt, [e.target.name]: e.target.value };
        // tempOptions[index] = opt;
        tempRange[`${name}`] = e;
         console.log({ tempRange });
        props.onChange({ ...props.question, range:tempRange });

        console.log(e);
    }


    return (
        <>
            <div className={classes.groupField}>
                {props.question.range && (
                 
                        <div className={classes.groupFieldInline} >

                            <Form.Item label="Start:" rules={[{ required: true }]}>
                                <InputNumber name="start" defaultValue={props.question.range.start} min={1} max={100} onChange={(e: any) => optionValueChange( "start", e)} />
                            </Form.Item>
                            <Form.Item className={classes.gapBetweenGroupField} label="End:" rules={[{ required: true }]}>
                                <InputNumber name="end" defaultValue={props.question.range.end}  min={1} max={100}  onChange={(e: any) => optionValueChange("end", e)} />
                            </Form.Item>
                            <Form.Item className={classes.gapBetweenGroupField} label="Step:" rules={[{ required: true }]}>
                                <InputNumber name="step" defaultValue={props.question.range.step} min={1} max={100} onChange={(e: any) => optionValueChange("step", e)} />
                            </Form.Item>
                          
                        </div>
                    )
                }
            </div>
       
        </>
    )
}