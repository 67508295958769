export const TEXT = 'Text';
export const SELECT_ONE = 'select_one';
export const MULTIPLE_SELECT = 'select_multiple';
export const ACKNOWLEDGE = 'Acknowledge';
export const AREA = 'Area';
export const BARCODE = 'Barcode';
export const NUMBER = 'Number';
export const FILE = 'File';
export const NOTE = 'Note';
export const CALCULATE = 'Calculate';
export const AUDIO = 'Audio';
export const DATE_TIME = 'DateTime';
export const DATE = 'Date';
export const DECIMAL = 'Decimal';
export const PHOTO = 'Photo';
export const POINT = 'Point';
export const QUESTION_MATRIX = 'Question Matrix';
export const RANGE = 'Range';
export const RANKING = 'Ranking';
export const TIME = 'Time';
export const VIDEO = 'Video';
export const RATING = 'Rating';
export const GROUP_QUESTION = 'Group Question';
export const LINE = 'Line';


