import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { setLayoutSettings } from '../../redux/FormSlice';
import axiosServices from '../../helpers/axiosService';
import { _deserailize } from '../FormDesigner/Settings/Options/utils';
import { fetchLanguageURL } from '../../configs/ServerUrl';
import { addLanguage, addSelectedLanguage, addDefaultLanguage } from '../../redux/LanguageSlice';
import { notification } from 'antd';

const LanguageSelection = ({ close }: any) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState<any>();
  const { questionList, tags } = useSelector((state: any) => state.questionBank);
  const [loadings, setLoadings] = useState<any>();
  const [selected, setSelected] = useState<any>([]);
  const [lang, setlang] = useState<any>([]);
  const [languageList, setLanguageList] = useState<any>([]);
  const [defaultLanguage, setDefaultLanguage] = useState<any>('');

  console.log('languages', languageList, selected);

  const fetchLanguage = async () => {
    const payload = {
      data: {
        type: 'languages',
        attributes: {
          language: {},
        },
      },
    };
    try {
      setLoadings(true);
      const response = await axiosServices.post(`${fetchLanguageURL}`, payload);
      let languages = await _deserailize(response.data);
      setLoadings(false);
      const defaultLang = languages.filter((l: any) => l.isDefault)[0];
      console.log('defaultLnage', defaultLang);

      languages = languages.filter((l: any) => !l.isDefault);

      console.log('reponse', languages);
      // dispatch(addLanguage(languages));
      setlang(languages);

      languages = languages.map((item: any) => ({
        ...item,
        label: item.name,
        value: item.id,
      }));

      setLanguageList(languages);

      setDefaultLanguage(defaultLang.name);
      dispatch(addDefaultLanguage(defaultLang));
      setLoadings(false);
    } catch (ex) {
      console.log(ex);
      notification['error']({
        message: 'Error',
        description: 'Cant fetch language',
      });
    }
  };

  useEffect(() => {
    fetchLanguage();
  }, []);

  return (
    <Grid container>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={4}>
          <Typography>Default Language</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            sx={{ width: '100%' }}
            id="filled-basic"
            label="Default Language"
            variant="outlined"
            value={defaultLanguage}
            disabled
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" sx={{ my: 4 }}>
        <Grid item xs={4}>
          <Typography>Select Other Preferred Languages</Typography>
        </Grid>
        <Grid item xs={6}>
          <Select
            isClearable
            isMulti
            options={languageList}
            isLoading={loadings}
            value={selected}
            onChange={(e: any) => {
              const tempLang = JSON.parse(JSON.stringify(e));
              setSelected(e);
              tempLang.map((l: any) => {
                delete l.value;
                delete l.label;
              });
              console.log('tem', tempLang);
              dispatch(addSelectedLanguage(tempLang));
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LanguageSelection;
