import { CloseOutlined } from '@ant-design/icons';
import { Box, IconButton } from '@material-ui/core';
import { Form, Input, Typography } from 'antd';
import { useSelector } from 'react-redux';
import React from 'react';
import ContactStyle from './Style';

export default function ManuallyValidation({ question, setValidationRender, hideCloseBtn, onEvent }: any) {
  const classes = ContactStyle();
  const defaultLanguage = useSelector((state: any) => state.language.defaultLanguage);
  return (
    <>
      <Box className={classes.closeOutlinedIcon}>
        {hideCloseBtn ? null : (
          <IconButton
            onClick={() => {
              setValidationRender('');
              onEvent('manualValidation', '');
            }}
          >
            <CloseOutlined className={classes.icon} />
          </IconButton>
        )}
      </Box>
      <Box className={classes.manualValidation}>
        <Box>
          <Typography.Text className={classes.manualValidationText}>Enter your validation code here :</Typography.Text>
          <Input.TextArea
            value={question.manualValidation}
            autoSize={{ minRows: 6, maxRows: 10 }}
            onChange={(e: any) => onEvent('manualValidation', e.target.value)}
          />
        </Box>
        <Box sx={{ marginTop: '30px' }}>
          <Typography.Text className={classes.manualValidationText}>
            {' '}
            {`Error Message ( ${defaultLanguage.code} ) - default :`}
          </Typography.Text>
          <Input.TextArea
            value={question.errorMsg[defaultLanguage.code]}
            autoSize={{ minRows: 2, maxRows: 4 }}
            onChange={(e: any) => onEvent('errorMsg', e.target.value)}
          />
        </Box>
      </Box>
    </>
  );
}
