import React from 'react';
import { Grid, Paper, Typography, Collapse } from '@mui/material';
import { GuidenceHintElement } from '../QuesOptFields';
import { useSelector } from 'react-redux';
import { CaretDownOutlined } from '@ant-design/icons';

const GuidanceHintGroup = (props: any) => {
  const defaultLanguage = useSelector((state: any) => state.language.defaultLanguage);
  const selectedLanguage = useSelector((state: any) => state.language.selectedLanguageList);

  return (
    <Grid container sx={{ pb: 3 }}>
      <Paper sx={{ borderTop: 1, borderColor: 'grey.300', width: '100%', mr: '20px', pb: 1 }} elevation={2}>
        {' '}
        <Grid item style={{ color: 'grey' }}>
          <Grid
            container
            alignItems="center"
            direction="row"
            aria-label="settings"
            onClick={() => props.setCollapseHint(!props.collapseHint)}
          >
            <Typography style={{ color: 'grey', fontSize: 15 }} sx={{ pl: 2, pr: '3px' }}>
              Guidance Hint
            </Typography>
            <CaretDownOutlined />
          </Grid>
          <Collapse in={!props.collapseHint}>
            <Grid container>
              <Grid item xs={12}>
                <GuidenceHintElement
                  question={props.question}
                  code={defaultLanguage.code}
                  onQuestionChange={props.onQuestionObjectChange}
                  isDefault={true}
                />
              </Grid>
              {selectedLanguage.map((lang: any) => (
                <Grid xs={12} key={lang.code}>
                  <GuidenceHintElement
                    question={props.question}
                    onQuestionChange={props.onQuestionObjectChange}
                    code={lang.code}
                    isDefault={false}
                  />
                </Grid>
              ))}
            </Grid>
          </Collapse>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default GuidanceHintGroup;
